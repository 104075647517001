.contact-teaser {
  $self: &;
  position: relative;
  overflow: hidden;
  padding: 0 20px;
  margin-top: 100px;

  @include md {
    display: grid;
    place-items: center;
  }

  &__headline {
    @include typo--hl;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &::before {
      content: "";
      width: 100px;
      display: inline-block;
      aspect-ratio: 1;
      background-image: url("/static/images/marcelloinsolera-contact.jpg");
      background-size: cover;
      border-radius: 20px;
      margin-bottom: 40px;

      @include lg {
        margin-right: 40px;
        margin-bottom: 0;
      }
    }

    @include lg {
      margin-top: 200px;
    }

    .page-about & {
      text-shadow: none;
      color: var(--clr-primary);
    }
  }

  &__contact-wrapper {
    position: relative;
    margin: 0 auto 100px;
    min-height: 200px;
    color: var(--clr-natural-yellow);
    padding-top: 60px;

    @include md {
      padding: 40px;
      display: inline-flex;
    }

    @include lg {
      margin: 0 auto 200px;
    }
  }

  &__legal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-left: auto;
    margin-bottom: 10px;
    width: 100%;
    position: relative;

    @include sm {
      flex-direction: row;
    }

    @include lg {
      margin-bottom: 20px;
    }
  }

  &__legal-nav {
    @include md {
    }
  }

  &__copyright {
    margin-bottom: 10px;
    @include md {
      margin-bottom: 0;
    }
  }

  &__copyright,
  &__legal-nav-link {
    font-size: 12px;
    line-height: 16px;

    .page-about & {
      color: var(--clr-primary);
    }
  }

  &__legal-nav-link {
    color: var(--clr-white);
    display: inline-block;
    position: relative;

    span {
      display: inline-block;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        transition: transform 0.5s ease;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: var(--clr-sky);
        transform: scaleX(0);
        transform-origin: 100% 0%;
      }
    }
    &:first-of-type {
      margin-right: 10px;
      @include md {
        margin-right: 30px;
      }
    }
    &:hover span:before {
      transform: scaleX(1);
      transform-origin: 0% 0%;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;

    @include md {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__link {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    flex: 1 0 auto;

    @include md {
      font-size: 20px;
    }
  }

  &__icon {
    position: absolute;
    display: block;
    right: -10px;
    top: -20px;
    height: 80px;
    z-index: -1;
    rotate: y 180deg;

    @include md {
      right: -30px;
      top: -20px;
    }
  }
}
