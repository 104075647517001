.text {
  @include moduleSpacing;

  &__headline {
    @include typo--hl(true);
    margin-bottom: 32px;
  }

  &__copy {
    @include md {
      width: 80%;
    }
  }
}
