.faq {
  position: relative;
  margin: 40px 0;
  padding: 40px 0;
  background-color: var(--clr-grey);
  border-radius: 40px;

  @include md {
    margin: 60px;
    padding: 40px;
  }

  &__headline {
    @include typo--hl(true);
    color: var(--clr-primary);
    margin-bottom: 30px;
    margin-left: 5%;

    @include lg {
      margin-left: 20px;
    }

    span {
      font-family: "Space";
      font-size: 50px;
    }
  }

  &__cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @media (hover) {
      &:has(.faq__card-inner:hover) {
        .faq__card:not(:hover) {
          scale: 0.95;
          .faq__card-question,
          .faq__card-close {
            opacity: 0.3;
          }
        }
      }
    }
  }

  &__card {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    transition: scale 300ms ease-in-out;

    @include lg {
      width: 50%;
    }

    &--open {
      .faq__card-question {
        opacity: 0;
        transform: translateY(-100%);
      }
      .faq__card-answer {
        opacity: 1;
        transform: translateY(-100%);
      }

      .faq__card-close {
        transform: translateY(-50%) rotate(0);
      }
    }
  }

  &__card-inner {
    position: relative;
    @include box();
    background: var(--clr-natural-yellow);
    padding: 20px 40px 20px 30px;
    margin: 20px;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    color: var(--clr-primary);

    @include md {
      padding: 20px 60px;
      height: 100%;
    }

    @media (hover) {
      &:hover:not(.faq__card--open) {
        .faq__card-close {
          transform: translateY(-50%) rotate(135deg);
        }
      }
    }
  }

  &__card-close {
    position: absolute;
    width: 24px;
    height: 24px;
    padding: 0;
    top: 50%;
    right: 20px;
    border: 0;
    background-color: transparent;
    color: var(--clr-primary);
    cursor: pointer;
    will-change: transform;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    transform: translateY(-50%) rotate(45deg);
    z-index: 2;
  }

  &__card-question,
  &__card-answer {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
  }

  &__card-question {
    font-size: 18px;
    font-weight: 600;
  }

  &__card-answer {
    opacity: 0;
  }
}
