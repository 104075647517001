// Small devices 576px
@mixin sm($max-width: null) {
  @if $max-width == "max" {
    @media (max-width: #{$screen-sm-min - 1px}) {
      @content;
    }
  } @else {
    @media (min-width: #{$screen-sm-min}) {
      @content;
    }
  }
}

// Medium devices 768px
@mixin md($max-width: null) {
  @if $max-width == "max" {
    @media (max-width: #{$screen-md-min - 1px}) {
      @content;
    }
  } @else {
    @media (min-width: #{$screen-md-min}) {
      @content;
    }
  }
}

// Large devices 1024px
@mixin lg($max-width: null) {
  @if $max-width == "max" {
    @media (max-width: #{$screen-lg-min - 1px}) {
      @content;
    }
  } @else {
    @media (min-width: #{$screen-lg-min}) {
      @content;
    }
  }
}

// Extra large devices 1280px
@mixin xl($max-width: null) {
  @if $max-width == "max" {
    @media (max-width: #{$screen-xl-min - 1px}) {
      @content;
    }
  } @else {
    @media (min-width: #{$screen-xl-min}) {
      @content;
    }
  }
}

// Extra Extra large devices 1440px
@mixin xxl($max-width: null) {
  @if $max-width == "max" {
    @media (max-width: #{$screen-xxl-min - 1px}) {
      @content;
    }
  } @else {
    @media (min-width: #{$screen-xxl-min}) {
      @content;
    }
  }
}

@mixin moduleSpacing {
  margin-block: 100px;

  @include lg {
    margin-block: 150px;
  }

  @include xxl {
    margin-block: 200px;
  }
}

@mixin moduleInnerSpacing {
  padding-block: 100px;

  @include lg {
    padding-block: 150px;
  }

  @include xxl {
    padding-block: 200px;
  }
}

@mixin box($br: 35px) {
  border-radius: $br;
  background: linear-gradient(145deg, #2b2e31, var(--clr-primary));
}

@mixin yellow {
  background-color: var(--clr-natural-yellow);
  color: var(--clr-primary);

  p {
    color: inherit;
  }
}

@mixin purple {
  background-color: var(--clr-secondary);
  color: var(--clr-primary);

  p {
    color: inherit;
  }
}

@mixin rose {
  background-color: var(--clr-sky);
  color: var(--clr-primary);

  p {
    color: inherit;
  }
}

@mixin dark {
  background-color: var(--clr-primary);
  color: var(--clr-off-white);

  p {
    color: inherit;
  }
}
