@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/montserrat/Montserrat-Bold.woff2") format("woff2"), url("/static/fonts/montserrat/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/montserrat/Montserrat-Light.woff2") format("woff2"), url("/static/fonts/montserrat/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/montserrat/Montserrat-Black.woff2") format("woff2"), url("/static/fonts/montserrat/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/montserrat/Montserrat-Regular.woff2") format("woff2"), url("/static/fonts/montserrat/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/static/fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"), url("/static/fonts/montserrat/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space";
  src: url("/static/fonts/Pilowlava-Regular.woff2") format("woff2"), url("/static/fonts/montserrat/Pilowlava-Regular.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@mixin typo--mega {
  font-weight: 900;
  font-size: 40px;
  line-height: 50px;
  color: var(--clr-white);
  text-shadow: 0 5px 10px rgba(47, 51, 54, 0.4);

  @include md {
    font-size: 64px;
    line-height: 70px;
  }

  @include xl {
    font-size: 72px;
    line-height: 76px;
  }
}

@mixin headline {
  font-weight: 900;
  line-height: 1.25;
  font-size: calc(clamp(2.25em, 7vw, 6em) * 0.875);
  color: var(--clr-off-white);
}

@mixin typo--hl($simple: false) {
  font-weight: 900;
  font-size: 26px;
  line-height: 28px;
  color: var(--clr-white);

  @include md {
    font-size: 36px;
    line-height: 40px;
  }
}

@mixin topline {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 700;
}

@mixin link {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.02em;
}

p,
a {
  color: var(--clr-white);
  font-size: 16px;
  line-height: 20px;
  @include md {
    font-size: 16px;
    line-height: 24px;
  }
}
