// generls settings
@import "/generals/reset.scss";
@import "/generals/variables.scss";
@import "/generals/base.scss";
@import "/generals/mixins.scss";
@import "/generals/typo.scss";

// all modules
@import "/partials/about.scss";
@import "/partials/button.scss";
@import "/partials/contact-teaser.scss";
@import "/partials/faq.scss";
@import "/partials/header.scss";
@import "/partials/intro.scss";
@import "/partials/job-hint.scss";
@import "/partials/projects.scss";
@import "/partials/rich-text.scss";
@import "/partials/stage.scss";
@import "/partials/stazioni.scss";
@import "/partials/text.scss";
@import "/partials/banner.scss";

// all pages
@import "/pages/about.scss";
@import "/pages/projects.scss";

body {
  #CybotCookiebotDialog {
    box-shadow: 5px 5px 10px var(--shadow-dark), -5px -5px 10px var(--shadow-light) !important;
  }
  #CybotCookiebotDialog,
  #CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider:before {
    background-color: var(--clr-primary) !important;
  }

  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    border-color: transparent;
    box-shadow: 5px 5px 10px var(--shadow-dark), -5px -5px 10px var(--shadow-light) !important;
    border-radius: 40px;
  }

  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    border-color: transparent;
    box-shadow: 5px 5px 10px var(--shadow-dark), -5px -5px 10px var(--shadow-light) !important;
    border-radius: 40px;
  }

  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    position: relative;
    overflow: hidden;
    color: var(--clr-white) !important;
    background: var(--clr-primary) !important;
    box-shadow: 5px 5px 10px var(--shadow-dark), -5px -5px 10px var(--shadow-light) !important;
    border-radius: 40px;
    border-color: transparent !important;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(133deg, var(--primary) 30%, var(--clr-sky) 110%) !important;
      transition: all 0.25s ease-in-out;
      z-index: -1;
    }
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
    color: var(--clr-white);
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
    border-bottom: 1px solid var(--clr-sky) !important;
    color: var(--clr-sky) !important;
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    color: var(--clr-sky) !important;
  }

  #CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background: linear-gradient(133deg, var(--primary) 30%, var(--clr-sky) 110%) !important;
  }
  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount),
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab,
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
  #CybotCookiebotDialog *,
  #CybotCookiebotDialogBodyUnderlay * {
    color: var(--clr-white) !important;
  }

  #CybotCookiebotDialogPoweredbyImage,
  #CybotCookiebotDialogPoweredbyCybot {
    filter: invert(1);
  }

  .CybotCookiebotFaderLeft {
    background-image: linear-gradient(to right, var(--clr-white), rgba(var(--clr-white), 0.8), rgba(255, 255, 255, 0)) !important;
  }
  .CybotCookiebotFaderRight {
    background-image: linear-gradient(to left, var(--clr-white), rgba(var(--clr-white), 0.8), rgba(255, 255, 255, 0)) !important;
  }
  .CybotCookiebotFader {
    background-image: linear-gradient(to top, var(--clr-white), rgba(var(--clr-white), 0.8), rgba(255, 255, 255, 0)) !important;
  }
}

.transition {
  position: fixed;
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
}

.transition li {
  width: 20%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  background: #fffffe;
}

.yellow {
  @include yellow;
}

.rose {
  @include rose;
}

.purple {
  @include purple;
}

.dark {
  @include dark;
}
