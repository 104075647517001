.about {
  --pos: 0;
  $self: &;
  @include moduleSpacing;
  position: relative;
  color: var(--clr-primary);

  @include sm {
    display: flex;
    flex-direction: column;
  }

  &__headline--highlight {
    font-family: "Space";
    font-size: 50px;
    line-height: 1.25;
  }

  &__intro {
    @include moduleInnerSpacing;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    --color: rgba(114, 114, 114, 0.7);
    background-image: linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%, transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%, transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%, transparent);
    background-size: 55px 55px;
    mask-image: linear-gradient(to bottom, transparent, var(--clr-off-white) 10% 90%, transparent);

    @include md {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__service {
    color: var(--clr-primary);
    @include moduleSpacing;

    @include md {
      margin-inline: 20px;
    }

    .about__headline {
      margin-bottom: 54px;

      @include lg {
        margin-bottom: 108px;
      }
    }
  }

  &__service-items {
  }

  &__service-item {
    margin-block: 80px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  &__service-item-inner {
    width: 350px;
    aspect-ratio: 1;
    position: relative;
    border-radius: 40px;
    background-color: var(--bg);
    padding: 40px;
    padding-top: 80px;
    color: var(--clr-off-white);
    grid-column: var(--col);

    transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;

    &[data-inview] {
      opacity: 0;
      transform: translateY(20px);
    }

    &.inview {
      opacity: 1;
      transform: none;
      span {
        animation-name: jello;
      }
    }
  }

  &__service-number {
    position: absolute;
    top: -20px;
    left: 0;
    font-weight: 900;
    font-size: 4rem;
    background-color: var(--clr-grey-light);
    color: var(--clr-primary);
    padding: 20px;
    padding-bottom: 30px;
    // background: repeating-linear-gradient(45deg, var(--clr-natural-yellow), var(--clr-natural-yellow) 10px, var(--clr-primary) 10px, var(--clr-primary) 20px);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    isolation: isolate;
    border-bottom-right-radius: 30px;
    background-color: var(--clr-grey-light);

    span {
      display: inline-block;
      transform-origin: center bottom;
      animation-duration: 800ms;
      animation-delay: 250ms;

      @keyframes bounce {
        from,
        20%,
        53%,
        to {
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, 0, 0);
        }

        40%,
        43% {
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          transform: translate3d(0, -30px, 0) scaleY(1.1);
        }

        70% {
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          transform: translate3d(0, -15px, 0) scaleY(1.05);
        }

        80% {
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, 0, 0) scaleY(0.95);
        }

        90% {
          transform: translate3d(0, -4px, 0) scaleY(1.02);
        }
      }

      @keyframes rubberBand {
        from {
          transform: scale3d(1, 1, 1);
        }

        30% {
          transform: scale3d(1.25, 0.75, 1);
        }

        40% {
          transform: scale3d(0.75, 1.25, 1);
        }

        50% {
          transform: scale3d(1.15, 0.85, 1);
        }

        65% {
          transform: scale3d(0.95, 1.05, 1);
        }

        75% {
          transform: scale3d(1.05, 0.95, 1);
        }

        to {
          transform: scale3d(1, 1, 1);
        }
      }

      @keyframes jello {
        from,
        11.1%,
        to {
          transform: translate3d(0, 0, 0);
        }

        22.2% {
          transform: skewX(-12.5deg) skewY(-12.5deg);
        }

        33.3% {
          transform: skewX(6.25deg) skewY(6.25deg);
        }

        44.4% {
          transform: skewX(-3.125deg) skewY(-3.125deg);
        }

        55.5% {
          transform: skewX(1.5625deg) skewY(1.5625deg);
        }

        66.6% {
          transform: skewX(-0.78125deg) skewY(-0.78125deg);
        }

        77.7% {
          transform: skewX(0.390625deg) skewY(0.390625deg);
        }

        88.8% {
          transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        }
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
    }

    &::before {
      bottom: -30px;
      left: 0;
      background-color: var(--clr-bg);
      border-top-left-radius: 25px;
      box-shadow: -5px -5px 0px 5px var(--clr-grey-light);
    }
    &::after {
      top: 20px;
      right: -30px;
      height: 20px;
      background-color: var(--clr-bg);
      border-top-left-radius: 25px;
      box-shadow: -5px -5px 0px 5px var(--clr-grey-light);
    }
  }

  &__service-title {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 32px;
    color: var(--font-clr);
  }

  &__service-copy {
    color: var(--font-clr);
  }

  &__intro-text {
    width: 100%;
    color: var(--clr-primary);

    @include sm {
      width: 400px;
      margin-top: 40px;
    }
  }

  &__img-wrapper {
    width: 100%;
    margin-top: 32px;

    @include sm {
      margin-top: 0;
      width: calc(100% - 420px);
    }
  }

  &__img {
    --br-img: 35px;
    border-radius: var(--br-img);
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;

    @include sm {
      --br-img: 40px;
    }

    @include md {
      aspect-ratio: 4 / 5;
    }

    @include lg {
      aspect-ratio: 16 / 9;
    }
  }

  &__img-image {
    --pos: 0%;
    height: 110%;
    width: 110%;
    object-fit: cover;
    object-position: center;

    @include lg {
      object-position: 0% var(--pos);
    }
  }

  &__text-wrapper {
    @include moduleSpacing;
    position: relative;
    padding: 60px 20px;
    border-radius: 40px;
    background-color: var(--clr-off-white);

    @include md {
      padding: 150px 60px;
    }
  }

  &__headline {
    @include typo--hl(true);
    color: var(--clr-primary);
    margin-bottom: 30px;
    overflow: hidden;

    &[data-inview] {
      > span {
        display: block;
        transition: transform 600ms 300ms ease-in-out;
        transform: translateY(100%);
      }
    }

    &.inview {
      > span {
        transform: none;
      }
    }
  }

  &__headline-ghost {
    @include typo--mega;
    color: var(--clr-primary);
    opacity: 0.15;
    width: 100vw;

    white-space: nowrap;
    z-index: -1;

    &:first-of-type {
      margin-left: 35vw;
      margin-top: 400px;
    }

    &:last-of-type {
      margin-left: -80vw;
      margin-bottom: 400px;
    }
  }

  &__copy {
    position: relative;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: calc(24 / 18);
    padding-left: 10px;
    color: var(--clr-primary);

    @include md {
      width: 60%;
      padding-left: 20px;
    }
  }

  &__button {
    margin-top: 60px;

    @include md {
      margin-left: 20px;
    }
  }
}
