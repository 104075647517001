.job-hint {
  $self: &;
  --clr-alert: #00ff87;
  position: relative;
  color: var(--clr-white);
  z-index: 2;
  max-height: 54px;
  background: var(--clr-primary);
  border-radius: 12px;
  padding: 10px 20px;

  @media (hover) {
    &:hover {
      #{$self}__info-text,
      #{$self}__arrow,
      #{$self}__free-time {
        opacity: 1;
        transform: none;
      }

      #{$self}__icon {
        transform: translateX(110%);
      }

      #{$self}__arrow {
        transition-delay: 150ms;
        transform: translateX(5px);
      }
    }
  }

  &--closed {
    --clr-alert: #ef233c;
    cursor: not-allowed;

    #{$self}__link-container::before {
      animation: pulse_red 2s infinite;
    }

    &::after {
      display: none;
    }

    #{$self}__icon--not-available {
      display: block;
    }
    #{$self}__icon--available {
      display: none;
    }

    &:hover {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: shake;
    }
  }

  &--hidden {
    transform: translateY(-200%);
  }

  &__link-container {
    display: flex;
    align-items: center;
    height: 25px;

    &::before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      margin-right: 20px;
      border-radius: 50%;
      background: var(--clr-alert);
      animation: pulse_it 2s infinite;
    }
  }

  &__link-text {
    width: max-content;
  }

  &__icon-container {
    position: relative;
    margin-left: 8px;
    width: 25px;
    height: 25px;
    overflow: hidden;
  }

  &__icon {
    width: 25px;
    height: 25px;
    transition: transform 0.25s ease-in-out;
    &--not-available {
      display: none;
    }
  }

  &__arrow {
    transition: opacity 0.2s ease-in-out, transform 0.25s ease-in-out;
    opacity: 0;
  }

  &__arrow {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 16px;
  }

  @keyframes pulse_it {
    0% {
      box-shadow: 0 0 0 0 rgba(#00ff87, 0.4);
    }
    70% {
      box-shadow: 0 0 0 16px rgba(#00ff87, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(#00ff87, 0);
    }
  }

  @keyframes pulse_red {
    0% {
      box-shadow: 0 0 0 0 rgba(#ef233c, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(#ef233c, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(#ef233c, 0);
    }
  }

  @keyframes shake {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }
}
