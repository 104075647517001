.rich-text {
  position: relative;
  @include md {
    margin: 0 auto 100px auto;
    width: 80%;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 24px;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 22px;
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  a {
    color: var(--clr-white);
    display: inline-block;
    position: relative;

    span {
      display: inline-block;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        transition: transform 0.5s ease;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: var(--clr-sky);
        transform: scaleX(0);
        transform-origin: 100% 0%;
      }
    }
    &:hover span:before {
      transform: scaleX(1);
      transform-origin: 0% 0%;
    }
  }
}
