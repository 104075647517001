.stazioni {
  position: relative;
  @include moduleSpacing;
  margin-block: 150px;

  @include md {
    margin-inline: 20px;
  }

  &__headline {
    @include typo--hl(true);
    color: var(--clr-primary);
    margin-bottom: 30px;

    span {
      font-family: "Space";
      font-size: 50px;
    }
  }

  &__note {
    font-size: 12px;
    margin-top: 20px;
    color: var(--clr-primary);
  }

  &__items {
    width: 100%;
    padding: 40px 10px;

    @include md {
      padding: 40px 20px;
    }

    @include lg {
      width: 80%;
    }

    @include xxl {
      width: 60%;
    }
  }

  &__item {
    position: relative;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border-top: 1px solid var(--clr-primary);
    transition: opacity 400ms ease-in-out, transform 300ms ease-in-out;

    &[data-inview] {
      opacity: 0;
      transform: translateY(20px);
    }

    &.inview {
      opacity: 1;
      transform: none;
    }

    @include md {
      padding: 40px 20px;
      flex-direction: row;
    }

    &:last-of-type {
      border-bottom: 1px solid var(--clr-primary);
    }

    p,
    a {
      color: var(--clr-primary);
    }

    a {
      margin-top: 8px;
      font-size: 14px;
    }

    &:after {
      position: absolute;
      top: 5%;
      left: 5%;
      background-image: linear-gradient(133deg, var(--primary) 30%, var(--clr-sky) 110%);
      height: 100%;
      width: 100%;
      border-radius: 14px;
      z-index: -1;
      filter: blur(8px);
    }
  }

  &__fun-facts {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include md {
      gap: 40px;
      flex-direction: row;
    }

    @include lg {
      margin-top: 40px;
    }

    &-item {
      border-radius: 30px;
      padding: 20px;
      width: 300px;
    }

    p {
      &:last-child {
        margin-top: 60px;
        font-size: 26px;
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }

  &__date {
    margin-right: 40px;
  }

  &__agency {
    margin-top: 24px;
    @include lg {
      margin-top: 0;
      margin-right: 40px;
    }
  }

  &__url {
    margin-inline: 20px;
  }

  &__job {
    margin-top: 32px;
    font-size: 14px;
    padding: 8px 12px;
    border: 2px solid var(--clr-secondary);
    border-radius: 30px;
    width: max-content;

    @include md {
      margin-left: auto;
    }

    @include lg {
      margin-top: 0;
    }
  }
}
