.page-about {
  background-color: var(--clr-mist);

  .header__logo-icon {
    animation: colorChangeNeg 60s steps(1) infinite;

    @media (hover) {
      &:hover {
        animation: colorChangeNeg 300ms steps(5) infinite;
      }
    }
  }

  .contact-teaser__contact-wrapper {
    color: var(--clr-secondary);
  }

  .button {
    --color: var(--clr-primary);
    --color-hover: var(--clr-primary);
    --border-color: var(--clr-secondary);
  }
}
