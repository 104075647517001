.stage {
  --hint-border: var(--clr-mist);
  --hint-bg: var(--clr-natural-yellow);
  --hint-color: var(--clr-primary);
  --bg: var(--clr-primary);
  --color: var(--clr-off-white);
  margin-block: 100px;
  background-color: var(--bg);
  border-radius: 30px;

  @include md {
    margin-block: 150px 100px;
  }

  &__inner {
    height: 80dvh;
    display: grid;
    place-items: center;
    position: relative;

    @include md {
      height: 60vh;
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 30px;
    background-color: var(--bg);
  }

  &__hint {
    position: absolute;
    bottom: calc(var(--hintSize) / 2 * -1);
    left: 50%;
    margin-top: calc(var(--hintSize) / 2 * -1);
    height: var(--hintSize);
    width: var(--hintSize);
    background: var(--hint-bg);
    border-radius: 50%;
    border: 10px solid var(--hint-border);
    display: grid;
    place-items: center;
    z-index: 1;
    cursor: pointer;

    &::after,
    &::before {
      --size: 20px;
      content: "";
      position: absolute;
      height: var(--size);
      width: var(--size);
      background-color: transparent;
      top: 50%;
      transform: translateY(-19px);
    }

    &::before {
      left: -28px;
      border-bottom-right-radius: 20px;
      box-shadow: 6px 6px var(--hint-border);
    }

    &::after {
      right: -28px;
      border-bottom-left-radius: 20px;
      box-shadow: -6px 6px var(--hint-border);
    }

    &-inner {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
      border-radius: 50%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--clr-sky);
        z-index: -1;
        transition: transform 250ms ease-in-out;
        transform: translate(-100%, 20%);
      }
    }

    span {
      color: var(--hint-color);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.02em;
      transition: scale 300ms ease-in-out;
    }

    svg {
      position: absolute;
      top: 55%;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 24px;
      color: var(--hint-color);
    }

    @media (hover) {
      &:hover {
        .projects__contact-inner::before {
          transform: none;
        }
        span {
          scale: 1.15;
        }

        svg {
          animation: scrollArrow 800ms infinite;
        }
      }
    }
  }

  @keyframes scrollArrow {
    to {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  &__headline {
    position: relative;
    @include headline;
    width: 80%;
    color: var(--color);
  }

  .char {
    display: inline-block;
    transform-origin: 50% 100%;
  }

  .word {
    display: inline-block;
    overflow: hidden;
  }
}
