html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

body {
  font-family: "Montserrat", sans-serif;
  color: var(--clr-white);
  background-color: var(--clr-primary);
  margin: 0 auto;
  scroll-behavior: smooth;
  scroll-margin-top: 80px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--clr-primary);
    transition: opacity 0.4s ease-in-out;
    z-index: 3;
  }

  &.show {
    &:before {
      opacity: 0;
      pointer-events: none;
    }
  }

  &:has(.header__menu-text--open) {
    .body-content {
      filter: blur(5px);
      pointer-events: none;
    }
  }
}

.body-content {
  transition: filter 300ms ease-in-out;
}

.main-content {
  max-width: 1920px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  overflow: hidden;
}

.icon-sprite {
  display: none;
}

:root {
  --clr-primary: #303336;
  --clr-secondary: #969ac6;
  --clr-accent: #ff0a54;
  --clr-accent-2: #faff00;
  --clr-accent-light: #ff7096;
  --clr-grey: #d7d8db;
  --clr-grey-light: #efefef;
  --clr-grey-dark: #bfbfbf;
  --clr-off-white: #fbfbfb;
  --font-size: 7vw;
  --primary: #b386fd;
  --clr-white: #ecf0f3;
  --shadow-dark: #292b2e;
  --shadow-light: #373b3e;
  --gradient: linear-gradient(133deg, var(--clr-secondary) 30%, var(--clr-sky) 110%);
  --hintSize: 150px;

  --clr-dark-forest: #152023;
  --clr-natural-yellow: #e6fe52;
  --clr-stone: #dfdbc8;
  --clr-mist: #efefef;
  --clr-sky: #a8c0c3;
}

@media (min-width: 1024px) {
  :root {
    --hintSize: 200px;
  }
}

.webgl {
  position: absolute;
  top: 0;
  z-index: 100;
}
