.header {
  position: fixed;
  top: 0;

  width: 100%;
  z-index: 2;

  &__inner {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1920px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  &__logo {
    position: relative;
    color: var(--clr-off-white);
    font-weight: 900;
    display: block;
    overflow: hidden;

    span {
      display: inline-block;
      white-space: nowrap;
      transition: all 0.4s ease-in-out;

      &:last-of-type {
        position: absolute;
        left: 101%;
      }
    }

    &:hover {
      span {
        &:first-of-type {
          translate: -100% 0;
        }
        &:last-of-type {
          left: 0;
        }
      }
    }
  }

  &__logo-icon {
    width: 70px;
    height: 40px;
    animation: colorChange 60s steps(1) infinite;

    @media (hover) {
      &:hover {
        animation: colorChange 300ms steps(5) infinite;
      }
    }
  }

  @keyframes colorChange {
    0% {
      color: var(--clr-off-white);
    }
    25% {
      color: var(--clr-secondary);
    }
    50% {
      color: var(--clr-sky);
    }
    75% {
      color: var(--clr-natural-yellow);
    }
    100% {
      color: var(--clr-off-white);
    }
  }

  @keyframes colorChangeNeg {
    0% {
      color: var(--clr-primary);
    }
    25% {
      color: var(--clr-secondary);
    }
    50% {
      color: var(--clr-sky);
    }
    75% {
      color: var(--clr-natural-yellow);
    }
    100% {
      color: var(--clr-primary);
    }
  }

  &__nav-frame {
    @include md("max") {
      position: fixed;
      right: 20px;
      bottom: 20px;
    }

    position: relative;
  }

  &__nav {
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    display: flex;
    padding: 8px;
    transition: background 300ms ease-in-out;
    backdrop-filter: blur(10px);

    @media (hover) {
      &:hover {
        background: var(--clr-grey);

        .header__nav-list-item {
          span {
            color: var(--clr-primary);
          }
        }
      }
    }

    &:has(.header__menu-text--open) {
      @include md("max") {
        background: var(--clr-grey);

        .header__nav-wrapper {
          scale: 1;
          border-radius: 18px;
        }

        .header__menu-text {
          span {
            color: var(--clr-primary);

            &:first-of-type {
              transform: translateY(100%);
            }

            &:last-of-type {
              transform: none;
            }
          }
        }

        .header__nav-list-item span {
          opacity: 1;
        }
      }
    }
  }

  &__menu-text {
    all: unset;
    @include link;
    transition: scale 300ms ease-in-out;
    overflow: hidden;
    position: relative;
    padding-inline: 24px;

    @include md {
      display: none;
    }

    span {
      display: block;
      color: var(--clr-grey);
      transition: transform 300ms ease-in-out, color 300ms ease-in-out;

      &:last-of-type {
        position: absolute;
        top: 0;
        left: 24px;
        transform: translateY(-100%);
      }
    }
  }

  &__nav-wrapper {
    @include md("max") {
      background: var(--clr-grey);
      border-radius: 20%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-block: 20px 60px;
      padding-inline: 32px;
      position: absolute;
      right: 0;
      scale: 0;
      transform-origin: 100% 100%;
      transition: all 300ms ease-in-out;
    }

    display: flex;
    gap: 20px;
    padding-inline: 20px 0;
    align-items: center;
  }

  &__nav-list {
    @include md("max") {
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      padding-bottom: 20px;
    }

    display: flex;
    gap: 20px;

    flex-direction: row;
  }

  &__nav-list-item {
    @include link;
    overflow: hidden;
    position: relative;

    span {
      display: inline-block;
      color: var(--clr-primary);
      transition: opacity 300ms 200ms ease-in-out;
      opacity: 0;

      @include md {
        transition: transform 300ms ease-in-out, color 300ms ease-in-out;
        color: var(--clr-grey);
        opacity: 1;
      }

      &:last-of-type {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
      }
    }

    @media (hover) {
      &:hover {
        span {
          color: var(--clr-primary);

          &:first-of-type {
            transform: translateY(100%);
          }

          &:last-of-type {
            transform: none;
          }
        }
      }
    }
  }
}
