.button {
  --color: var(--clr-off-white);
  --color-hover: var(--clr-primary);
  --border-color: var(--clr-natural-yellow);
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 16px 32px;
  box-sizing: border-box;
  border-radius: 20px;
  transition: all 400ms;
  overflow: hidden;
  cursor: pointer !important;
  border: 2px solid var(--border-color);
  color: var(--color);
  width: max-content;

  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    transform: translate(-100%, 0) rotate(10deg);
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
    background-color: var(--clr-sky);
  }

  .char {
    display: inline-block;
    position: relative;
    transform-origin: 50% 100%;
  }

  @media (hover) {
    &:hover {
      color: var(--color-hover);
      border: 2px solid transparent;

      &:before {
        transform: none;
      }

      // .char {
      //   animation: jump 400ms calc(var(--char-index) * 0.075s) cubic-bezier(0.165, 0.44, 0.64, 1) forwards;
      // }
    }
  }

  @keyframes jump {
    20% {
      transform: translateY(2%) scaleY(0.9);
    }
    40% {
      transform: translateY(-60%) scaleY(1.2);
    }
    50% {
      transform: translateY(10%) scaleY(0.8);
    }
    70% {
      transform: translateY(-5%) scaleY(1);
    }
    80%,
    100% {
      transform: translateY(0) scaleY(1);
    }
  }
}
