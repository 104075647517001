.page-projects {
  .stage {
    --hint-border: var(--clr-primary);
    --color: var(--clr-primary);
    --bg: var(--clr-grey-light);
  }

  .button {
    --color: var(--clr-primary);
    --color-hover: var(--clr-primary);
    --border-color: var(--clr-sky);
  }
}
