.intro {
  width: 100vw;
  max-width: 1920px;
  position: relative;
  padding-bottom: 80px;
  margin: 0 auto;

  @include lg {
    height: 100dvh;
    padding-bottom: 0;
  }

  &:has(.intro__hint:hover) {
    .intro__head,
    .intro__image-svg {
      opacity: 0.7;
      filter: blur(20px);
    }

    .intro__marquee {
      transition: opacity 400ms ease-in-out;
      opacity: 1;
    }

    .intro__marquee-line {
      animation-play-state: running;
    }
  }

  &__inner {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  &__image-wrapper {
    @include lg("max") {
      grid-area: 1 / 2 / 2 / 2;
      margin-inline: 10px;
    }

    @include md("max") {
      grid-area: 1 / 1 / 2 / 3;
      margin-inline: 10px;
    }

    position: relative;
    display: grid;
    place-items: center;
  }

  &__image-svg {
    color: var(--clr-grey);
    position: absolute;
    top: -300px;
    right: -100px;
    width: 100%;
    height: auto;
    z-index: -1;
    transition: filter 300ms ease-in-out, opacity 300ms ease-in-out, rotate 2000ms ease-in-out, scale 2200ms ease-in-out;
    rotate: -10deg;
    display: none;

    @include lg {
      display: block;
      width: 50%;
      rotate: -5deg;
      top: inherit;
      bottom: 15%;
      right: 60%;
      scale: 1.75;
    }

    .show & {
      rotate: -10deg;
      scale: 1.8;
    }
  }

  &__hint {
    position: absolute;
    bottom: 0;
    right: 50px;
    margin-bottom: calc(var(--hintSize) / 2 * -1);
    height: var(--hintSize);
    width: var(--hintSize);
    background: var(--clr-natural-yellow);
    border-radius: 30% 70% 51% 49% / 60% 45% 55% 40%;
    border-radius: 50%;
    border: 10px solid var(--clr-primary);
    z-index: 1;
    cursor: pointer;

    @include lg {
      top: 50%;
      right: calc(var(--hintSize) / 2 * -1);
      margin-top: calc(var(--hintSize) / 2 * -1);
    }

    &::after,
    &::before {
      --size: 20px;
      content: "";
      position: absolute;
      height: var(--size);
      width: var(--size);
      background-color: transparent;

      @include lg {
        translate: -9px;
        left: 50%;
        margin-left: calc(var(--size) / 2 * -1);
      }
    }

    &::before {
      @include lg("max") {
        right: -30px;
        top: 45px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        box-shadow: -10px 9px var(--clr-primary);
      }

      top: -28px;
      border-bottom-right-radius: 20px;
      box-shadow: 6px 6px var(--clr-primary);
    }

    &::after {
      @include lg("max") {
        bottom: 65px;
        border-bottom-right-radius: 20px;
        box-shadow: 5px 6px 0px 2px var(--clr-primary);
        left: -28px;
      }

      bottom: -28px;
      border-top-right-radius: 20px;
      box-shadow: 6px -6px var(--clr-primary);
    }

    span {
      display: block;
      color: var(--clr-primary);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.02em;
      transition: scale 300ms ease-in-out;
    }

    &-inner {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
      border-radius: 50%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--clr-sky);
        z-index: -1;
        transition: transform 250ms ease-in-out;
        transform: translate(-100%, 20%);
      }
    }

    @media (hover) {
      &:hover {
        .intro__hint-inner::before {
          transform: none;
        }

        span {
          scale: 1.15;
        }
      }
    }
  }

  &__image-holder {
    position: relative;
    max-width: 550px;
    aspect-ratio: 3 / 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include lg {
      margin-right: 100px;
    }
  }

  &__image-frame {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 40px;
    filter: brightness(0.8);

    grid-area: 1 / 1;

    &--body {
      transform-origin: 50% 15%;
      transition: scale 200ms ease-in-out;
    }

    @include md {
      filter: brightness(1);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 30%;
    transition: opacity 300ms ease-in-out;

    @include md {
      object-position: top;
    }
  }

  &__head {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: flex-start;
    padding-left: 40px;
    position: relative;
    transition: filter 300ms ease-in-out, opacity 300ms ease-in-out;
    z-index: 1;
    grid-area: 1 / 1 / 2 / 3;
    padding-top: 150px;

    @include md {
      height: 85vh;
    }

    @include lg {
      padding-top: 0;
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  &__headline {
    position: relative;
    color: white;
    display: block;
  }

  &__slogan {
    font-size: 18px;
    line-height: 20px;
    font-weight: 900;
    display: block;

    cursor: default;

    @include lg {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__service {
    position: relative;
    cursor: default;
    display: block;
    overflow: hidden;

    &-item {
      @include typo--mega;
      text-transform: uppercase;
    }
  }

  &__content {
    position: relative;
  }

  &__button {
    margin-top: 30px;
    transform: translateX(-20px);
  }

  &__lead-in {
    margin-top: 24px;
    @include md {
      margin-top: 48px;
    }
  }

  &__marquee-outer {
    position: absolute;
    border-radius: 40px;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__marquee-inner {
    position: absolute;
    width: 100%;
    top: 30%;
    left: 0;
    transform: translate(0, -50%) rotate(-20deg);
  }

  &__marquee {
    --duration: 6s;
    --gap: var(--space);

    display: flex;
    user-select: none;
    gap: var(--gap);
    transform: skewY(-3deg);
    opacity: 0;

    &:first-child {
      .intro__marquee-line {
        --ani-dur: 20s;
      }
    }

    &:nth-child(2) {
      .intro__marquee-line {
        --ani-dur: 15s;
        animation-direction: reverse;
      }
    }

    &:last-child {
      .intro__marquee-line {
        --ani-dur: 18s;
      }
    }
  }

  &__marquee-line {
    margin-block: 10px;
    text-transform: uppercase;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll var(--ani-dur) linear infinite;
    animation-play-state: paused;

    span {
      @include typo--mega;
      padding: 0 20px;
    }
  }

  .magnatic-cta {
    @include md("max") {
      display: none;
    }
  }

  [data-word] {
    overflow: hidden;
  }

  .char {
    display: inline-block;
    transform-origin: 50% 100%;
  }

  .words {
    overflow: hidden;
  }

  @keyframes scroll {
    100% {
      transform: translateX(-100%);
    }
  }
}
