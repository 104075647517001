.banner {
  --width: 250px;
  --height: 50px;
  --time: 15s;
  width: 100%;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);

  &__list {
    display: flex;
    width: 100%;
    position: relative;
  }

  &__item {
    text-align: center;
    width: var(--width);
    height: var(--height);
    position: absolute;
    left: 0%;
    animation: autoRun var(--time) linear infinite;
    font-size: 32px;
    font-weight: 900;
    animation-delay: calc((var(--time) / var(--quantity)) * (var(--position) - 1) - var(--time)) !important;
  }
}

@keyframes autoRun {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}
