.projects {
  --pos: 0;
  @include moduleSpacing;

  &:has(.clients--pxlcrtrs:hover) {
    .projects__banner-img-frame--pxlcrtrs {
      transform: none;
    }
  }

  &:has(.clients--gcd:hover) {
    .projects__banner-img-frame--gcd {
      transform: none;
    }
  }

  &:has(.clients--lchh:hover) {
    .projects__banner-img-frame--lchh {
      transform: none;
    }
  }

  &__intro {
    position: relative;
    min-height: 50vh;
    background: var(--clr-grey);
    border-radius: 30px;
    padding-block: 60px 100px;
    padding-inline: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-block: 100px 250px;

    @include md {
      padding-block: 60px;
    }

    h2 {
      @include typo--hl(true);
      color: var(--clr-primary);
      width: 90%;

      @include md {
        width: 80%;
      }
    }

    p {
      color: var(--clr-primary);
      width: 80%;

      @include md {
        width: 60%;
      }
    }
  }

  &__contact {
    position: absolute;
    bottom: calc(var(--hintSize) / 2 * -1);
    left: 5px;
    margin-top: calc(var(--hintSize) / 2 * -1);
    height: var(--hintSize);
    width: var(--hintSize);
    background: var(--clr-natural-yellow);
    border-radius: 50%;
    border: 10px solid var(--clr-primary);
    display: grid;
    place-items: center;
    z-index: 1;
    cursor: pointer;

    @include md {
      left: 50%;
    }

    &::after,
    &::before {
      --size: 20px;
      content: "";
      position: absolute;
      height: var(--size);
      width: var(--size);
      background-color: transparent;
      top: 50%;
      transform: translateY(-19px);
    }

    &::before {
      left: -28px;
      border-bottom-right-radius: 20px;
      box-shadow: 6px 6px var(--clr-primary);
    }

    &::after {
      right: -28px;
      border-bottom-left-radius: 20px;
      box-shadow: -6px 6px var(--clr-primary);
    }

    &-inner {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
      border-radius: 50%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--clr-sky);
        z-index: -1;
        transition: transform 250ms ease-in-out;
        transform: translate(-100%, 20%);
      }
    }

    span {
      color: var(--clr-primary);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.02em;
      transition: scale 300ms ease-in-out;
    }

    @media (hover) {
      &:hover {
        .projects__contact-inner::before {
          transform: none;
        }
        span {
          scale: 1.15;
        }
      }
    }
  }

  &__wrapper {
    @include md {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    @include lg {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 0;
    }
  }

  &__content {
    padding-block: 40px;
    padding-left: 10px;
    grid-area: 1 / 1 / 2 / 4;

    @include md {
      padding-right: 40px;
    }

    @include lg {
      grid-area: 1 / 1 / 1 / 3;
    }
  }

  &__topline {
    font-size: 50px;
    font-family: "Space";
    margin-bottom: 24px;
  }

  &__headline {
    @include typo--hl;
    margin-bottom: 32px;
  }

  &__copy {
    padding-left: 10px;

    @include md {
      padding-left: 20px;
    }
  }

  &__clients {
    display: grid;
    gap: 40px;

    @include md {
      display: flex;
      gap: 20px;
      grid-area: 2 / 1 / 3 / 4;
    }

    @include lg {
      display: grid;
      row-gap: 30px;
      grid-area: 2/1/3/1;
    }
  }

  &__banner {
    display: grid;
    position: relative;
    overflow: hidden;

    @include md {
      grid-area: 3 / 1 / 4 / 4;
    }

    @include lg {
      grid-area: 2 / 2 / 2 / 4;
    }

    &-img-frame {
      position: absolute;
      inset: 0;
    }

    &-img {
      --imgPos: calc(var(--pos) / 2);
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 40px;
      object-position: var(--imgPos) top;
      overflow: hidden;
    }
  }
}

.projects__banner-img-frame--gcd,
.projects__banner-img-frame--lchh,
.projects__banner-img-frame--pxlcrtrs {
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%);
}

.clients {
  border-radius: 35px;
  background-color: var(--clr-natural-yellow);
  color: var(--clr-primary);
  padding: 32px;
  min-height: 265px;
  position: relative;
  overflow: hidden;
  isolation: isolate;

  &::after {
    border-radius: 35px;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform-origin: center center;
    background-color: var(--clr-sky);
    transform: translate(-110%, 100%) rotate(10deg);
    transition: 0.2s transform ease-out;
    overflow: hidden;
    z-index: -1;
  }

  @media (hover) {
    &:hover {
      &::after {
        transform: none;
      }
    }
  }
}

.clients__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--clr-primary);
}

.clients__header {
  margin-bottom: 40px;
  max-width: 280px;

  span {
    font-family: "Courier New", Courier, monospace;
    font-weight: 900;
    font-size: 16px;
    text-transform: lowercase;
  }
}

.clients__name {
  color: var(--clr-primary);
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.clients__link {
  margin-top: auto;
  display: flex;
  align-items: center;
  color: var(--clr-primary);
  transition: transform 400ms ease-in-out;

  svg {
    margin-left: 16px;
    transition: rotate 400ms ease-in-out;
  }

  @media (hover) {
    &:hover {
      transform: translateX(10px);
      svg {
        rotate: -35deg;
      }
    }
  }
}
